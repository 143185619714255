.App {
  text-align: center;
  background-color: rgb(227, 227, 227);
  height:100%;
  width: 100%;
  position: absolute;
  overflow: auto;
}

#appHeader {
  text-align: center;
  font-style: bold;
  margin: 10px 0px;
  display: flex
}

#titleDiv{
  color: green;
  flex:1;
  border-right:3px solid rgba(0, 128, 0, 0.462);
  margin: 0px 5%
}
#importForm{
  flex:1;
  text-align: left;
  /* margin: 0px 5% */
}
#importForm h4{
  margin-top: 12px;
}
#fileInput{
  width: 70%
}
#submitBtnDiv{
  display:flex;
  /* vertical-align: center; */
}
#submitBtnDiv p{
  margin-top: 10px;
  margin-bottom: 0px;
  padding-top:1px;
  padding-left:5px;
  vertical-align: center;
  color:firebrick;
  font-weight: bold
}
#fileInputBtn{
  align-items: left;
  margin-top:10px;
}
#headerLineBreak{
  align-items: center;
  border-bottom: 3px solid rgba(0, 128, 0, 0.462);
  display: flex;
  margin: 0px 16px;
  text-align: center;
}

.topNavLink{
  padding: 10px 20px
}
.projMasterDiv{
  /* margin-bottom:20px; */
  margin: 15px 10%;
  border-bottom: 3px solid rgba(0, 128, 0, 0.462);
}
#tableOuter {
  width: 90%;
  margin-left: 5%;
  overflow-x: auto;
}

.tableHead {
  margin-left:5%;
  margin-bottom:10px;
  display: flex;
  flex-direction: row;
}
.tableCaption{
  border-right: 3px solid rgba(0, 128, 0, 0.462);
  margin: 0;
  padding: 20px
}
.buttonContainer{
  display: grid;
  gap:0px
}
.buttonDiv {
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.buttonDiv label{
  margin-right: 5px;
  flex: 4;
  text-align: right;
}
.buttonDiv button{
  flex: 1;
  width: 70px
}
#btn1{
  grid-column-start:1;
  grid-column-end: 2;
  grid-row-start:1;
  grid-row-end: 2
}
#btn2{
  grid-column-start:2;
  grid-column-end: 3;
  grid-row-start:1;
  grid-row-end: 2
}

/* table main */
.mainTable {
  border-collapse: collapse;
  width: max-content;
  border: 2px solid black
}
.mainTable th {
  border: 1px solid black;
  user-select: none;
}
.mainTable td {
  border: 1px solid black;
  user-select: none;
}



/* table head */
thead tr {
  background-color: #C6E0B4;
  border-bottom: 3px solid #000;
}
thead tr th{
  padding: 5px 10px
}

/* table body */
tbody tr {
  background-color: white
}
tbody tr td{
  padding: 2px 8px
}

/* table last */

/* first column */
.sticky{
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #A9D08E;
}
.stickySpan{
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #A9D08E;

}
.rates{
  position: sticky;
  z-index: 1;
  background-color: #A9D08E;
}
.userName{
  font-weight: bold;
  text-align: left;
  background-color: #C6E0B4;
  padding-right:10px
}
.userRate{
  font-weight: bold;
  background-color: #C6E0B4;
}

tbody tr:last-child {
  border-top: 3px solid #000;
  background-color: #C6E0B4;
}



/* end */



.positiveCell{
  font-weight:550;
  color:black
}
.zeroCell{
  color:rgba(0, 0, 0, 0.546)
}

::selection{
  background: none
}
.selected{
  background: rgba(52, 52, 255, 0.31);
}
.copyBtnsDiv{
  display:flex;
  padding-left:20%;
  padding-top:10px;
  width: 50%;
  padding-bottom: 20px
}
.copyBtnsDiv button{
  flex: 2;
  margin: 0px 30px;
}
.copyBtnsDiv p{
  flex: 1;
  margin: 0;
  vertical-align: center;
  color: firebrick
}

#projGraphLinkHead{
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  padding: 5px 20px;
}

.projGraphLink{
  display: block;
  text-decoration: none;
  padding: 5px;
  font-size: 14px;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  text-align: center;
  transition: background-color 0.3s ease;
}

.projGraphLink:hover {
  background-color: #A9D08E;
  font-weight: bold
}

g text tspan{
  left: 80px;
  -webkit-transform: rotate(90deg);
}